<template>
  <div class="dingbox">
    <div class="ding">
      <el-table :data="order" stripe height="99%" width="100%">
        <el-table-column label="状态" width="150">
          <template #default="scope">
            <div class="taitu" @click="part(scope.row.rescueId)">
              <img class="li-img" :src="scope.row.svg2" alt="" />
              <span class="zhuang" :style="{ background: scope.row.color }">{{
                scope.row.statusType
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="订单类型" width="80">
          <template #default="scope">
            <span class="take1" v-if="scope.row.rescueStyle == '即时单'"
              >即时单</span
            >
            <span class="take2" v-if="scope.row.rescueStyle == '预约单'"
              >预约单</span
            >
            <span class="take3" v-if="scope.row.rescueStyle == '顺路单'"
              >顺路单</span
            >
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <span class="paixustyle" @click="paixu" v-if="yinshengxu"
              >救援时间</span
            >
            <span class="paixustyle" @click="paixu2" v-if="yinjiangxu"
              >救援时间</span
            >
          </template>

          <template #default="scope">
            {{ scope.row.rescueTime }}
          </template>
        </el-table-column>

        <el-table-column label="所属公司" width="160">
          <template #default="scope">
            {{ scope.row.partner }}
          </template>
        </el-table-column>

        <el-table-column label="位置" :width="flexColumnWidth(order, 'max', 'address', 'mudi')">
          <template #default="scope">
            {{ scope.row.address }}
            <br />
            {{ scope.row.destination }}
          </template>
        </el-table-column>


        <el-table-column label="距离" width="120">
          <template #default="scope">
            {{ scope.row.distance }}公里
          </template>
        </el-table-column>

        <el-table-column label="费用" width="120">
          <template #default="scope">
            {{ scope.row.fee }}元
          </template>
        </el-table-column>

        <el-table-column label="协作商信息" width="230" v-if="getismain">
          <template #default="scope">
            {{ scope.row.takeSp }}{{ scope.row.takeSp !== "" ? "/" : ""
            }}{{ scope.row.xiezuo }}<br />
            {{ scope.row.xinxi }}
            <button
              @click="call(scope.row.takemobile, scope.row.rescueId)"
              class="call"
              v-show="getEmic != '' && scope.row.takemobile != ''"
            ></button>
          </template>
        </el-table-column>

        <el-table-column label="调度" width="170" v-if="isMain">
          <template #default="scope">
            <strong v-html="scope.row.diaodu"></strong>
          </template>
        </el-table-column>

        <el-table-column label="客服" width="170" v-if="isMain">
          <template #default="scope">
            <strong v-html="scope.row.kefu"></strong>
          </template>
        </el-table-column>

        <el-table-column prop="chargeType" label="收费类型" width="100" />

        <el-table-column label="业务员" width="170" v-if="isMain">
          <template #default="scope">
            <strong v-html="scope.row.saleman"></strong>
          </template>
        </el-table-column>

        <el-table-column label="订单来源" width="%10" >
          <template #default="scope">
            <span>{{ scope.row.fromtype }}</span>
          </template>
        </el-table-column>

        <el-table-column label="车主信息" width="180">
          <template #default="scope">
            {{ scope.row.rescueName }}/{{ scope.row.rescueCarNumber }}
          </template>
        </el-table-column>

        <el-table-column label="编号" :width="flexColumnWidth(order, 'max', 'rescueId')">
          <template #default="scope">
            <i @click="part(scope.row.rescueId)" class="xiangqing">{{
              scope.row.rescueId
            }}</i>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" v-if="isGroup == false">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="copyR(scope.row.rescueId)"
              v-if="
                scope.row.canCopy
              "
            >
              复制
            </el-button>

            <el-button
              link
              type="primary"
              size="small"
              @click="filfull(scope.row.rescueId)"
              v-if="
                getismain &&
                scope.row.canReview &&
                (scope.row.status == 20 || scope.row.status == 29) &&
                scope.row.isReview == 0
              "
            >
              审核
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              disabled
              style="background: #bcbcbc; border: 0"
              v-if="
                getismain &&
                !(
                  scope.row.canReview &&
                  (scope.row.status == 20 || scope.row.status == 29) &&
                  scope.row.isReview == 0
                ) &&
                !(
                  scope.row.canReview &&
                  (scope.row.status == 20 || scope.row.status == 29) &&
                  scope.row.isReview == 1
                ) &&
                !(
                  scope.row.canReview &&
                  (scope.row.status == 20 || scope.row.status == 29) &&
                  scope.row.isReview == 2
                ) &&
                !(
                  scope.row.canReview &&
                  (scope.row.status == 20 || scope.row.status == 29) &&
                  scope.row.isReview == 3
                )
              "
            >
              审核
            </el-button>

            <el-button
              link
              type="primary"
              size="small"
              @click="filfull(scope.row.rescueId)"
              v-if="
                getismain &&
                scope.row.canReview &&
                (scope.row.status == 20 || scope.row.status == 29) &&
                scope.row.isReview == 1
              "
            >
              初审未过
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              v-if="
                getismain &&
                scope.row.canReview &&
                (scope.row.status == 20 || scope.row.status == 29) &&
                scope.row.isReview == 2
              "
            >
              终审未过
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              v-if="
                getismain &&
                scope.row.canReview &&
                (scope.row.status == 20 || scope.row.status == 29) &&
                scope.row.isReview == 3
              "
            >
              审核通过
            </el-button>
            <!-- 回访 -->
            <el-button
              link
              type="primary"
              size="small"
              v-if="
                getismain &&
                scope.row.canReturnvisit &&
                scope.row.isReturn == 0 &&
                scope.row.status >= 20
              "
              @click="returnvisit(scope.row.rescueId)"
            >
              回访
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              disabled
              style="background: #bcbcbc; border: 0"
              v-if="
                getismain &&
                !(
                  scope.row.canReturnvisit &&
                  scope.row.isReturn == 0 &&
                  scope.row.status >= 20
                ) &&
                !(
                  scope.row.canReturnvisit &&
                  scope.row.isReturn == 1 &&
                  scope.row.status >= 20
                )
              "
            >
              回访
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              disabled
              style="background: #bcbcbc; border: 0"
              v-if="
                getismain &&
                scope.row.canReturnvisit &&
                scope.row.isReturn == 1 &&
                scope.row.status >= 20
              "
            >
              已回访
            </el-button>

            <!-- 投诉 -->
            <el-button
              link
              type="primary"
              size="small"
              @click="plan(scope.row.rescueId)"
              v-if="
                getismain &&
                scope.row.canComplaint &&
                scope.row.isComplaint == 0
              "
            >
              投诉
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="plan(scope.row.rescueId)"
              v-if="
                getismain &&
                scope.row.canComplaint &&
                scope.row.isComplaint == 1
              "
            >
              已投诉
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="allNum">总共{{ this.total }}条</div>
  </div>
  <complain :iscome="come" :complainId="rescueId" @complainsx="sx1"></complain>
  <part :islook="look" :id="rescueId" @checkbtn="checkbtn"></part>
  <check
    :isaudio="audio"
    :rescueIdcheck="rescueId"
    @checksx="sx"
    @partbtn="partbtn"
  ></check>
  <score
    v-if="huifang"
    :ishui="huifang"
    :huifangMain="huifangTit"
    :ishuiid="huiId"
    @closehui="closehui"
  ></score>
  <copy :iscopyset="copyset" :copyid="copyrescueid"></copy>
</template>

<script>
import Check from "./check.vue";
import complain from "./complain.vue";
import Part from "./part.vue";
import * as api from "../api/order";
import * as api1 from "../api/orderDetail";
import Score from "./score.vue";
import * as ola from "../api/olawebsock.js";
import copy from "./copy.vue";

export default {
  // props:["isAdd"],
  props: {
    isAdd: {
      type: Array,
      default: ["skdfhks"],
    },
    isrefresh: "",
  },
  components: { complain, Check, Part, Score,copy },
  data() {
    return {
      come: 0,
      audio: 0,
      look: 0,
      huifang: false,
      rescueId: "",
      order: [],
      orderlog: [],
      orderdetail: {},
      orderphoto: [],
      photo1: [],
      photo2: [],
      photo3: [],
      isMain:true,
      pagesize: 1,
      ismain: "",
      total: "",
      datas: {
        page: 1,
        perpage: 6,
      },
      data: {
        type: 1,
        page: 1,
        perpage: 8,
        keytype: "",
        keyword: null,
        rescueStyle: 1,
        createStart: "",
        createEnd: "",
        yuyueStart: "",
        yuyueEnd: "",
        city: "",
      },
      huifangTit: {},
      huiId: "",
      emicreg: "",
      timer: null,
      isChange: true,
      mudi: [],
      mudidi: [],
      yutishi: -1,
      yinshengxu: true,
      yinjiangxu: false,
      copyrescueid: "",
      copyset: 0,
      isGroup:false
    };
  },

  async created() {
    this.res_orderList(this.datas);
  },
  methods: {
    async res_orderList(data) {
      let res_orderList = await api.orderList(data);
      console.log("订单列表：", res_orderList);
      this.order = res_orderList.data;
      this.isMain = res_orderList.isMain;
      this.isGroup = res_orderList.isGroup;
      

      this.order = this.order.map((item) => {
        let xiezuo = item.takeUser;
        let xz = xiezuo.indexOf("/");
        let resxz = xiezuo.substring(0, xz);
        let resxinxi = xiezuo.substring(xz + 1, xiezuo.length);
        return {
          ...item,
          xiezuo: resxz,
          xinxi: resxinxi,
        };
      });
      console.log("订单信息：", this.order);

      this.total = parseInt(res_orderList.total);
    },
    async res_orderListSx(data) {
      console.log(data);
      let res_orderList = await api.orderListsx(data);
      this.order = res_orderList.data;
      this.order = this.order.map((item) => {
        let xiezuo = item.takeUser;
        let xz = xiezuo.indexOf("/");
        let resxz = xiezuo.substring(0, xz);
        let resxinxi = xiezuo.substring(xz + 1, xiezuo.length);
        return {
          ...item,
          xiezuo: resxz,
          xinxi: resxinxi,
        };
      });
      this.total = parseInt(res_orderList.total);
    },
    copyR(id) {
      this.copyrescueid = id;
      this.copyset++;
      if (this.copyset == 3) {
        this.copyset = 1;
      }

      //console.log(this.copyset);
    },

    plan(id) {
      this.come++;
      if (this.come == 3) {
        this.come = 1;
      }
      this.rescueId = id;
    },
    async filfull(id) {
      this.audio++;
      if (this.audio == 3) {
        this.audio = 1;
      }
      this.rescueId = id;
    },

   

    closehui() {
      this.huifang = false;
      this.res_orderList(this.datas);
    },

    mouseenterFun(index) {
      console.log("悬浮----", index);
      this.yutishi = index;
    },
    mouseleaveFun() {
      this.yutishi = -1;
    },

    // 时间排序
    paixu() {
      this.yinshengxu = false;
      this.yinjiangxu = true;
      this.order.sort(function (a, b) {
        return (
          new Date(a.rescueTime).getTime() - new Date(b.rescueTime).getTime()
        );
      });
    },
    paixu2() {
      this.yinshengxu = true;
      this.yinjiangxu = false;
      this.order.sort(function (a, b) {
        return (
          new Date(b.rescueTime).getTime() - new Date(a.rescueTime).getTime()
        );
      });
      // console.log("排序后数组：", sortArry);
    },

    async returnvisit(rescue_id) {
      let res = await api.getEvaluate(rescue_id);
      console.log("回访信息---------", res.data);
      this.huifangTit = res.data;
      this.huifang = true;
      this.huiId = rescue_id;
      console.log("回访--------------", rescue_id);
    },
    sx() {
      this.res_orderListSx(this.datas);
    },
    sx1() {
      this.res_orderListSx(this.datas);
    },

    getInitial() {
      for (var i = 0; i < this.count; i++) {
        this.list.push(this.list[i]);
      }
    },

    

    part(id) {
      this.rescueId = id;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },

    checkbtn(e,btn) {
      btn.classList.add('partcengji')
      this.audio++;
      if (this.audio == 3) {
        this.audio = 1;
      }
      this.look = 0;
    },
    partbtn(e,btn) {
      this.rescueId = e;
      btn.classList.add('checkcengji')
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },


    // 自适应表格列宽
    flexColumnWidth(...args) {
      //console.log("接收参数", args);
      // 0:整个数组
      // 1:默认宽度
      // 2 && 之后:要排序的某个属性（可以多个）
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      var str;
      if (args && args.length) {
        if (args.length - 2 > 1) {
          // 多行，str数组
          [, , ...str] = [...args];
          //console.log("测试多行赋值：", str);
        } else {
          // 一行，str字符串
          [, , ...str] = [...args];
          str = str + "";
          //console.log("要排序的属性：", str);
        }
      } else {
        return;
      }
      var columnContent = "";
      var tableData = args[0];
      var flag = args[1] || "max";
      // 判断想要排序的属性是否非空，空直接return
      if (
        !tableData ||
        !tableData.length ||
        tableData.length === 0 ||
        tableData === undefined
      ) {
        return;
      }
      // 判断数组是否非空，空直接return
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return;
      }
      if (flag === "equal") {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            //console.log("该列数据1[0]:", tableData[0][str]);
            columnContent = tableData[i][str];
            break;
          }
        }
      } else {
        if (Array.isArray(str)) {
          // 数组，多行
          var strArr = str.map((item, x, arr) => {
            let index = 0;
            for (let i = 0; i < tableData.length; i++) {
              if (tableData[i][item] === null) {
                return;
              }
              const now_temp = tableData[i][item] + "";
              const max_temp = tableData[index][item] + "";
              if (now_temp.length > max_temp.length) {
                index = i;
              }
            }
            return tableData[index][item];
          });

          strArr.sort((x, y) => {
            // 降序
            return y.length - x.length;
          });
          columnContent = strArr[0];
        } else {
          // 字符串，一行
          let index = 0;
          for (let i = 0; i < tableData.length; i++) {
            if (tableData[i][str] === null) {
              return;
            }
            const now_temp = tableData[i][str] + "";
            const max_temp = tableData[index][str] + "";
            if (now_temp.length > max_temp.length) {
              index = i;
            }
          }
          columnContent = tableData[index][str];
        }
        // 获取该列中最长的数据(内容)
      }
      //console.log("该列数据最长的内容:", columnContent);
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0;
      for (const char of columnContent) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配10个单位宽度
          flexWidth += 10;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配15个单位宽度
          flexWidth += 15;
        } else {
          // 其他种类字符，为字符分配10个单位宽度
          flexWidth += 15;
        }
      }
      if (flexWidth < 100) {
        // 设置最小宽度
        flexWidth = 100;
      }
      // if (flexWidth > 250) {
      //   // 设置最大宽度
      //   flexWidth = 250
      // }
      return flexWidth + "px";
    },

  },

  // 滚动加载数据
  mounted() {
    let tabelList = document.querySelector(".el-scrollbar__wrap");
    tabelList.addEventListener(
      "scroll",
      () => {
        if (this.timer) clearTimeout(this.timer); //清除定时器
        this.timer = setTimeout(async () => {
          let x = 10; //滚动条块到底的距离
          if (
            tabelList.offsetHeight + tabelList.scrollTop >=
            tabelList.scrollHeight - x
          ) {
            if (this.isChange && this.order.length < this.total) {
              this.isChange = false;
              this.datas.page++;
              // 发送页码请求
              let res_orderList = await api.orderList(this.datas);

              res_orderList = res_orderList.data;

              res_orderList = res_orderList.map((item) => {
                let xiezuo = item.takeUser;
                let xz = xiezuo.indexOf("/");
                let resxz = xiezuo.substring(0, xz);
                let resxinxi = xiezuo.substring(xz + 1, xiezuo.length);
                return {
                  ...item,
                  xiezuo: resxz,
                  xinxi: resxinxi,
                  // mudi:newArray[index],
                };
              });

              // 滚动加载数据
              this.order = [...this.order, ...res_orderList];

              if (this.yinjiangxu == true) {
                this.order.sort(function (a, b) {
                  return (
                    new Date(a.rescueTime).getTime() -
                    new Date(b.rescueTime).getTime()
                  );
                });
              } else if (this.yinjiangxu == false) {
                this.order.sort(function (a, b) {
                  return (
                    new Date(b.rescueTime).getTime() -
                    new Date(a.rescueTime).getTime()
                  );
                });
              }
              console.log("订单滚动列表：", this.order);
              this.isChange = true;
            } else {
              this.$message.warning("没有更多了");
            }
          }
        }, 500);
      },
      true
    );
  },

  beforeMount() {
    this.getInitial();
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
    getEmic() {
      return this.$store.state.emic;
    },
  },

  watch: {
    isAdd(newv) {
      console.log("筛选后的改变的订单列表：", newv);
      this.datas = newv;
      this.res_orderList(this.datas);
    },
    isrefresh(newv) {
      console.log(newv);

      this.res_orderList(this.datas);
    },
  },
};
</script>

<style lang="less" scoped>
.dingbox {
  //position: absolute;
  // top: 380px;
  //left: 250px;
  width: 1610px;
  height: 470px;
}
.ding {
  height: 470px;
  position: relative;
}

.dingbox /deep/ .el-table-fixed-column--right:last-of-type {
  width: 250px;
  display: flex;
  align-items: center;
  height: 70px;
}

.dingbox /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  color: #000;
  height: 50px;
}
.dingbox /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.dingbox /deep/ .el-table__cell {
  padding: 0;
}
.dingbox /deep/ .el-button--small {
  width: 60px;
}
.dingbox /deep/ .is-vertical {
  width: 15px !important;
}
.dingbox /deep/ .is-horizontal {
  height: 15px !important;
}

thead {
  text-align: left;
  box-sizing: border-box;
  background-color: #f8fafc;
  height: 50px;
  line-height: 50px;
}
tr {
  height: 70px;
  box-sizing: border-box;
  color: #727e96;
  font-size: 14px;
}
.zhuang {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  color: #fff;
  margin-top: 3px;
  box-sizing: border-box;
}
i {
  font-style: normal;
  color: #2c68ff;
}
strong {
  color: #2a3346;
}
.tiao {
  display: inline-block;
  width: 0;
  height: 14px;
  border: 1px solid #dadfe6;
  margin: 0 10px;
  box-sizing: border-box;
}
.jinXing-sorter {
  display: flex;

  justify-content: flex-end;
  position: absolute;
  right: 30px;
  bottom: 0px;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}

.number {
  width: 210px;
}
.collaborate {
  width: 208px;
}
.charge {
  width: 100px;
}
.state {
  width: 150px;
}
.found {
  width: 180px;
}
.team {
  width: 200px;
}
.check,
.know,
.visit,
.complain {
  cursor: pointer;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}

.allNum {
  font-size: 14px;
  text-align: right;
  color: #2c68ff;
  height: 40px;
  line-height: 40px;
}

.take,
.take1,
.take2,
.take3 {
  display: inline-block;
  // width: 80px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}
.take1 {
  color: #2c68ff;
  background: #e8f3fe;
}
.take2 {
  color: #27cc8b;
  background: #eefbf4;
}
.take3 {
  color: #fff6e9;
  background: #ff9100;
}
i,
.xiangqing {
  font-style: normal;
  color: #2c68ff;
}
.xiangqing {
  cursor: pointer;
}
.maketit {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #6d6262;
  padding: 2px 5px;
  box-sizing: border-box;
  color: #fff;
}
.yuyuetime {
  color: #27cc8b;
  cursor: pointer;
}
.paixustyle {
  cursor: pointer;
}
.li-img {
  float: left;
  width: 40px;
  height: 40px;
}
.taitu {
  display: flex;
  width: 130px;
  cursor: pointer;
}

</style>

<style>
.partcengji{
  z-index: 30 !important;
}
.checkcengji{

  z-index: 20 !important;
}
</style>
