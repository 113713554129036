import Cookies from "js-cookie";

var websock = null;

var global_callback = null;
var serverPort = "80"; // webSocket连接端口
var wsuri = "wss://dispatch.nituowola.com/websocket/";
var nowuid = null;

function createWebSocket(callback) {
    let uid = Cookies.get("ntwl-uid");
    if(nowuid == uid)
    {
      if (websock == null) {
        initWebSocket(callback);
      } else{

        if(websock.readyState == 1)
        {

          global_callback = callback;
          websock.onmessage = function (e) {
            websocketonmessage(e);
          };
        }else{

          initWebSocket(callback);
        }
      }
    }else{
      if(nowuid == null)
      {
        if (websock == null) {
          initWebSocket(callback);
        } 
      }else{
        initWebSocket(callback);
      }
    }
}

function initWebSocket(callback) {
  global_callback = callback;

  if(websock != null)
  {
    websock.close();

    setTimeout(function(){
      websock = new WebSocket(wsuri);
      websock.onmessage = function (e) {
          websocketonmessage(e);
      };
      websock.onclose = function (e) {
          websocketclose(e);
      };
      websock.onopen = function () {
          websocketOpen();
      };
    
      // 连接发生错误的回调方法
      websock.onerror = function () {
        console.log("WebSocket连接发生错误");
         //createWebSocket();啊，发现这样写会创建多个连接，加延时也不行
      };
    },1000);
  }else{
    websock = new WebSocket(wsuri);
    websock.onmessage = function (e) {
        websocketonmessage(e);
    };
    websock.onclose = function (e) {
        websocketclose(e);
    };
    websock.onopen = function () {
        websocketOpen();
    };
  
    // 连接发生错误的回调方法
    websock.onerror = function () {
      console.log("WebSocket连接发生错误");
    };
  }
}

// 实际调用的方法
function sendSock(agentData ) {
  
  if(websock != null)
  {
    if (websock.readyState === websock.OPEN) {
      websocketsend(agentData);
    } else if (websock.readyState === websock.CONNECTING) {
      setTimeout(function () {
        sendSock(agentData);
      }, 1000);
    } else {
      setTimeout(function () {
        sendSock(agentData);
      }, 1000);
    }
  }
 
}

function closeSockS() {
  if(websock !== null ){
    websock.close();
  }
  
}

function closeSock() {
  if(websock !== null ){
    websock.onclose = function (e) {
      websocketclose(e);
    };
  }
  
}

// 数据接收
function websocketonmessage(msg) {
  global_callback(msg.data);

}

// 数据发送
function websocketsend(agentData) {
  websock.send(agentData);
}

// 关闭
function websocketclose(e) {
  global_callback('connection:closed');
  websock = null;
}

function websocketOpen(e) {
  let uid = Cookies.get("ntwl-uid");
  let authkey = Cookies.get("ntwl-authkey");
  nowuid = uid;
  websocketsend("login " + uid + " " + authkey);
}

export { sendSock, createWebSocket, closeSock,closeSockS };
